import {
  Box,
  Button,
  Flex,
  Heading,
  SimpleGrid,
  Tag,
  TagLabel,
  TagLeftIcon,
  Text,
  Textarea,
  useColorMode,
  useColorModeValue,
  useToast,
  Wrap,
  WrapItem,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { RiMapPinLine, RiMoonLine, RiRoadMapLine, RiSunLine } from 'react-icons/ri'
import { useParams } from 'react-router-dom'
import * as yup from 'yup'
import { RadioGroup } from '../../../components/form/RadioGroup'
import { toastError } from '../../../config/error/toastError'
import { useQueryParams } from '../../../hooks/useQueryParams'
import { Logo } from '../../../layout/Header/Logo'
import { fetchOneFreight } from '../../../services/endpoints/freights'
import { useGetMapDirections } from '../../../services/endpoints/freights/getMapDirections'
import { publicApiServer } from '../../../services/publicApi'
import { FreightType, NpsMotoristType, ResponseApiType } from '../../../services/types'

interface RequestParams {
  freight_id: string
}

interface CreateMotoristFeedbackData {
  freight_id: string
  spotx_note: number
  operator_note: number
  suport_note: number
  payment_method_note: number
  collect_and_delivery_note: number
  recommendation_score: number
  reason: string
}

const FeedbackFormSchema = yup.object().shape({
  recommendation_score: yup.number().when('short_feedback', {
    is: 1,
    then: yup.number().required('Campo obrigatório'),
    otherwise: yup.number(),
  }),
  reason: yup.string(),
})

export function MotoristFeedback(): JSX.Element {
  const shortFeedback = useQueryParams('short-feedback')
  const toast = useToast()
  const card = useColorModeValue('gray.300', 'gray.700')
  const { colorMode, toggleColorMode } = useColorMode()
  const [reason, setReason] = useState<string>()
  const { freight_id } = useParams<RequestParams>()
  const [freight, setFreight] = useState<FreightType>()
  const getMapDirections = useGetMapDirections(freight?.origin, freight?.destination)
  const [researchCompleteAnswered, setResearchCompleteAnswered] = useState(false)
  const { setValue, formState, handleSubmit } = useForm({
    resolver: yupResolver(FeedbackFormSchema),
  })
  const { errors, isSubmitting } = formState

  useEffect(() => {
    const loadFreight = async () => {
      const data = await fetchOneFreight({
        freight_id,
        relations: [
          'nps_motorists',
          'client',
          'motorist',
          'vehicle',
          'trailer',
          'origin',
          'destination',
          'vehicle.vehicle_body',
          'vehicle.vehicle_category',
        ],
      })

      setFreight(data)
    }
    loadFreight()
  }, [freight_id])

  async function handleCreateResearch(data: CreateMotoristFeedbackData) {
    const researchData = {
      freight_id,
      recommendation_score: data.recommendation_score,
      reason,
    }
    try {
      const { data: response } = await publicApiServer.post<ResponseApiType<NpsMotoristType>>(
        `create-nps-motorist`,
        { ...researchData },
      )
      const { data: researchResponse } = response
      if (researchResponse.recommendation_score >= 0) {
        setResearchCompleteAnswered(true)
      }
      toast({
        title: 'Pesquisa concluída com sucesso!',
        isClosable: true,
        position: 'top-right',
        status: 'success',
      })
    } catch (error) {
      toastError({ toast, error })
    }
  }

  useEffect(() => {
    if (freight && freight.nps_motorists && freight.nps_motorists.recommendation_score >= 0) {
      setResearchCompleteAnswered(true)
    }
  }, [freight, freight?.nps_motorists?.recommendation_score])

  return (
    <Flex p="4" direction="column" overflowY="auto">
      <Flex px="4" alignItems="center" justifyContent="space-between">
        <Flex alignItems="center">
          <Logo />
        </Flex>
        <Box>
          {colorMode === 'light' ? (
            <RiMoonLine
              size="30px"
              cursor="pointer"
              onClick={() => {
                toggleColorMode()
              }}
            />
          ) : (
            <RiSunLine
              size="30px"
              cursor="pointer"
              onClick={() => {
                toggleColorMode()
              }}
            />
          )}
        </Box>
      </Flex>

      <Flex />

      {freight && (
        <>
          <Flex
            as="form"
            justifyContent="center"
            alignItems="center"
            gridGap={4}
            mt="4"
            direction={['column']}
            w="100%"
            onSubmit={handleSubmit(handleCreateResearch)}
            noValidate
          >
            <Flex
              fontSize={['lg', 'x-large']}
              alignItems="center"
              gridGap={4}
              flexDirection={['column', 'row']}
            >
              <Text fontSize={['lg', 'xx-large']} fontWeight="bold">
                Pesquisa de satisfação com motorista
              </Text>
            </Flex>
          </Flex>
          <Flex
            as="form"
            justifyContent="center"
            alignItems="center"
            gridGap={4}
            mt="4"
            direction={['column']}
            onSubmit={handleSubmit(handleCreateResearch)}
            noValidate
          >
            {researchCompleteAnswered ? (
              <Box bg={card} maxW="620px" p={8} borderRadius={8} shadow="lg">
                <Heading>Muito obrigado por sua resposta.</Heading>
              </Box>
            ) : (
              <>
                <Box bg={card} maxW="620px" p={8} borderRadius={8} shadow="lg">
                  <Text fontSize={['md', 'x-large']} fontWeight="bold" mb="4">
                    Informações do frete
                  </Text>
                  <SimpleGrid minChildWidth="150px" spacing="20" gridGap={8}>
                    <Wrap spacing="4" alignItems="center">
                      <WrapItem gridGap={2}>
                        <Text fontSize={['xs', 'md']} fontWeight="bold">
                          Cliente:
                        </Text>

                        <Text fontSize={['xs', 'md']}> {freight?.client.nickname}</Text>
                      </WrapItem>
                      <WrapItem gridGap={2}>
                        <Text fontSize={['xs', 'md']} fontWeight="bold">
                          Motorista:
                        </Text>

                        <Text fontSize={['xs', 'md']}> {freight?.motorist?.name}</Text>
                      </WrapItem>
                      <WrapItem gridGap={2}>
                        <Text fontSize={['xs', 'md']} fontWeight="bold">
                          Modelo e placa do veículo:
                        </Text>

                        <Text fontSize={['xs', 'md']}>
                          {freight?.vehicle?.vehicle_category?.name} - {freight.vehicle?.license_plate}
                        </Text>
                      </WrapItem>
                      <WrapItem gridGap={2}>
                        <Text fontSize={['xs', 'md']} fontWeight="bold">
                          Carroceria e placa:
                        </Text>

                        <Text fontSize={['xs', 'md']}>
                          {freight?.vehicle?.vehicle_body?.name} - {freight?.vehicle?.body_license_plate}
                        </Text>
                      </WrapItem>

                      {freight && (
                        <WrapItem alignItems="center">
                          <Tag
                            size="lg"
                            borderRadius="full"
                            backgroundColor="whiteAlpha.900"
                            color="gray.700"
                          >
                            <TagLeftIcon fontSize={['xx-small', 'sm']} as={RiMapPinLine} />
                            <TagLabel mr="3" fontSize={['xx-small', 'sm']}>
                              {freight.origin.name} - {freight.origin.state.uf}
                            </TagLabel>
                            {'>'}
                            <TagLeftIcon as={RiRoadMapLine} ml="2" />
                            <TagLabel fontSize={['xx-small', 'md']}>
                              {freight.destination.name} - {freight.destination.state.uf}
                            </TagLabel>
                            <Text fontSize={['xx-small', 'md']} ml="2">
                              ({getMapDirections.data?.metrics.distance})
                            </Text>
                          </Tag>
                        </WrapItem>
                      )}
                    </Wrap>
                  </SimpleGrid>
                </Box>

                <RadioGroup
                  hidden
                  name="short_feedback"
                  setValue={setValue}
                  initialValue={shortFeedback ? 1 : 0}
                  options={[{ label: 'Sim', value: 1 }]}
                />

                <Box bg={card} maxW="620px" p={8} borderRadius={8} shadow="lg">
                  <Text fontSize="lg" fontWeight="bold">
                    Em uma escala de 0 a 10 qual é a chance de você recomendar a SpotX para um outro
                    motorista? *
                  </Text>
                  <Text fontSize="sm">Sendo 0 muito ruim e 10 muito bom</Text>

                  <RadioGroup
                    mt="6"
                    direction={['row']}
                    name="recommendation_score"
                    error={errors.recommendation_score}
                    setValue={setValue}
                    options={[
                      { label: '0', value: 0 },
                      { label: '1', value: 1 },
                      { label: '2', value: 2 },
                      { label: '3', value: 3 },
                      { label: '4', value: 4 },
                      { label: '5', value: 5 },
                      { label: '6', value: 6 },
                      { label: '7', value: 7 },
                      { label: '8', value: 8 },
                      { label: '9', value: 9 },
                      { label: '10', value: 10 },
                    ]}
                  />
                </Box>
                <Box bg={card} maxW="620px" w="100%" p={8} borderRadius={8} shadow="lg">
                  <Text fontSize="md" fontWeight="bold" my="2">
                    Por quê você deu essa nota? (Fique à vontade para falar o que quiser)
                  </Text>
                  <Textarea
                    name="reason"
                    size="md"
                    value={reason}
                    onChange={e => {
                      setReason(e.target.value)
                    }}
                  />
                </Box>

                <Button colorScheme="blue" type="submit" isLoading={isSubmitting}>
                  Enviar resposta
                </Button>
              </>
            )}
          </Flex>
        </>
      )}
    </Flex>
  )
}
