/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Badge,
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  SimpleGrid,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Tooltip,
  useColorModeValue,
  useDisclosure,
  useToast,
  WrapItem,
} from '@chakra-ui/react'
import { is } from 'date-fns/locale'
import { useEffect, useState } from 'react'
import { GiReceiveMoney } from 'react-icons/gi'
import { GrLinkNext } from 'react-icons/gr'
import { IoCheckmark, IoCloseOutline } from 'react-icons/io5'
import { RiEditLine } from 'react-icons/ri'
import { Link, useHistory, useParams } from 'react-router-dom'
import { PreviewFiles } from '../../../../../components/DropzoneImageUpload/PreviewFiles'
import { toastError } from '../../../../../config/error/toastError'
import { useAuth } from '../../../../../contexts/AuthContext'
import { useAbility } from '../../../../../hooks/useAbility'
import { Layout } from '../../../../../layout'
import { useGetOneFreight } from '../../../../../services/endpoints/freights'
import { useGetCteIssuanceRequest } from '../../../../../services/endpoints/freights/GetCteIssuanceRequest'
import { getStatus } from '../../../../../services/endpoints/freights/getFreights'
import {
  CteIssuanceRequestAttachmentTypeEnum,
  CteIssuanceRequestStatusEnum,
  UsersRoleEnum,
} from '../../../../../services/types/EnumTypes'
import { cteIssuanceRequestStatusLegend } from '../../../../../services/utils/cteIssuanceRequestStatusLegend'
import formatCPFAndCNPJ from '../../../../../services/utils/formatCPFAndCNPJ'
import { getCteIssuanceRequestColorByStatus } from '../../../../../services/utils/getCteIssuanceRequestColorByStatus'
import { getNextStatusCteIssuanceRequest } from '../../../../../services/utils/getNextStatusCteIssuanceRequest'
import { getNextStatusLabelCteIssuanceRequest } from '../../../../../services/utils/getNextStatusLabelCteIssuanceRequest'
import { boxWidth } from '../../../../../services/utils/tableAdaptable'
import { ListCte } from '../../../show/cards/list-cte'
import { HandleCteIssuanceRequestStatusModal } from '../modals/HandleCteIssuanceRequestStatusModal'
import { UpdateCteIssuanceRequestModal } from '../modals/UpdateCteIssuanceRequestModal'

type RequestParams = {
  id: string
}

type AnalysisStatusType =
  | 'awaiting_issuance_cte'
  | 'rejected'
  | 'cte_issued'
  | 'cte_return_to_motorist'
  | 'awaiting_advance_request'
  | 'finished'

type SplitAttachmentsType = {
  nfAttachments: string[]
}
export function ShowCteIssuanceRequest(): JSX.Element {
  const [splitAttachments, setSplitAttachments] = useState<SplitAttachmentsType>()
  const toast = useToast()
  const { id } = useParams<RequestParams>()
  const bg = useColorModeValue('white', 'gray.800')
  const tabs = ['Notas Fiscais:']
  const history = useHistory()
  const { user } = useAuth()

  const [analysisStatus, setAnalysisStatus] = useState<AnalysisStatusType>('awaiting_issuance_cte')

  const {
    isOpen: isOpenHandleCteIssuanceRequestStatusModal,
    onOpen: onOpenHandleCteIssuanceRequestStatusModal,
    onClose: onCloseHandleCteIssuanceRequestStatusModal,
  } = useDisclosure()

  const {
    isOpen: isOpenUpdateCteIssuanceRequestModal,
    onOpen: onOpenUpdateCteIssuanceRequestModal,
    onClose: onCloseUpdateCteIssuanceRequestModal,
  } = useDisclosure()

  const { data: cteIssuanceRequestData, isLoading, isError, error } = useGetCteIssuanceRequest({ id })
  const {
    data: freight,
    isLoading: isLoadingFreight,
    error: errorFreight,
    isError: isErrorFreight,
  } = useGetOneFreight({
    freight_id: cteIssuanceRequestData?.freight_id!,
    relations: ['ctes'],
  })

  const ShowNextStatusButton =
    cteIssuanceRequestData?.status === CteIssuanceRequestStatusEnum.AWAITING_ISSUANCE_CTE ||
    cteIssuanceRequestData?.status === CteIssuanceRequestStatusEnum.CTE_ISSUED ||
    cteIssuanceRequestData?.status === CteIssuanceRequestStatusEnum.CTE_RETURN_TO_MOTORIST

  function handleStatus(status: AnalysisStatusType | null): void {
    if (status) {
      setAnalysisStatus(status)
      onOpenHandleCteIssuanceRequestStatusModal()
    }
  }

  useEffect(() => {
    if (isError) {
      toastError({ toast, error })
    }

    if (isErrorFreight) {
      toastError({ toast, error: errorFreight })
    }
  }, [toast, isError, error, errorFreight, isErrorFreight])

  useEffect(() => {
    if (
      cteIssuanceRequestData &&
      cteIssuanceRequestData.attachments &&
      cteIssuanceRequestData.attachments.length > 0
    ) {
      const nfAttachments = cteIssuanceRequestData.attachments
        .filter(
          attachment =>
            attachment.type === CteIssuanceRequestAttachmentTypeEnum.NF &&
            typeof attachment.attachment_file_url === 'string' &&
            attachment.attachment_file_url.trim() !== '',
        )
        .map(attachment => attachment.attachment_file_url!) as string[]

      setSplitAttachments({ nfAttachments })
    }
  }, [cteIssuanceRequestData])

  return (
    <Layout>
      {!isLoading ? (
        cteIssuanceRequestData ? (
          <>
            <Flex mb={2} alignItems="center" justifyContent="space-between">
              <Heading fontSize="2xl" display="flex" alignItems="center">
                <Text ml={2}>Frete #{cteIssuanceRequestData?.freight?.freight_number} - </Text>

                <Tooltip
                  label={cteIssuanceRequestStatusLegend(cteIssuanceRequestData?.status)}
                  hasArrow
                  placement="top"
                  arrowSize={15}
                >
                  <Badge
                    fontSize="sm"
                    px="2"
                    py="2"
                    colorScheme={getCteIssuanceRequestColorByStatus(cteIssuanceRequestData.status)}
                    ml={2}
                  >
                    {cteIssuanceRequestData.formatted_status}
                  </Badge>
                </Tooltip>
              </Heading>

              {cteIssuanceRequestData.status === CteIssuanceRequestStatusEnum.AWAITING_ANALYSIS &&
                user.role === UsersRoleEnum.LOGISTICS_ANALYST && (
                  <Box>
                    <Button
                      size="sm"
                      colorScheme="green"
                      onClick={() => {
                        handleStatus('awaiting_issuance_cte')
                      }}
                    >
                      <Icon fontSize={16} as={IoCheckmark} mr={1} /> Aprovar
                    </Button>

                    <Button
                      size="sm"
                      colorScheme="red"
                      ml={2}
                      onClick={() => {
                        handleStatus('rejected')
                      }}
                    >
                      <Icon fontSize={20} as={IoCloseOutline} mr={1} /> Reprovar
                    </Button>
                  </Box>
                )}

              {cteIssuanceRequestData.status === CteIssuanceRequestStatusEnum.REJECTED && (
                <Box>
                  <Button
                    size="sm"
                    colorScheme="orange"
                    onClick={() => {
                      onOpenUpdateCteIssuanceRequestModal()
                    }}
                  >
                    <Icon fontSize={16} as={RiEditLine} mr={1} /> Editar
                  </Button>
                </Box>
              )}

              {cteIssuanceRequestData.status === CteIssuanceRequestStatusEnum.AWAITING_ADVANCE_REQUEST &&
                !cteIssuanceRequestData.advance_request_id && (
                  <Box>
                    <Button
                      size="sm"
                      colorScheme="orange"
                      onClick={() => {
                        history.push(
                          `/advance-requests/create?freight_number=${cteIssuanceRequestData?.freight?.freight_number}&cte_issuance_request_id=${cteIssuanceRequestData.id}`,
                        )
                      }}
                    >
                      <Icon fontSize={16} as={GiReceiveMoney} mr={1} /> Criar solic. de adiantamento
                    </Button>
                  </Box>
                )}

              {cteIssuanceRequestData.status === CteIssuanceRequestStatusEnum.ADVANCE_REQUEST_FINISHED && (
                <Box>
                  {cteIssuanceRequestData.status ===
                    CteIssuanceRequestStatusEnum.ADVANCE_REQUEST_FINISHED && (
                    <Button
                      size="sm"
                      colorScheme="orange"
                      onClick={() => {
                        handleStatus('finished')
                      }}
                      ml={2}
                    >
                      <Icon fontSize={16} as={IoCheckmark} mr={1} /> Finalizar
                    </Button>
                  )}
                </Box>
              )}

              {ShowNextStatusButton && (
                <Box>
                  <Button
                    size="sm"
                    colorScheme="orange"
                    onClick={() => {
                      handleStatus(getNextStatusCteIssuanceRequest(cteIssuanceRequestData.status))
                    }}
                  >
                    {getNextStatusLabelCteIssuanceRequest(cteIssuanceRequestData.status)}{' '}
                    <Icon fontSize={12} as={GrLinkNext} ml={1} />
                  </Button>
                </Box>
              )}
            </Flex>
            {cteIssuanceRequestData.advance_request_id && (
              <Alert
                variant="left-accent"
                status="info"
                justifyContent="space-between"
                flexDirection={['column', 'row']}
                gridGap="2"
              >
                <Flex alignItems="center" gridGap="2">
                  <Flex flexDirection={['column']}>
                    <AlertTitle mr={2}>Solicitação de adiantamento vinculada!</AlertTitle>
                    <AlertDescription>
                      Esta solicitação de emissão de CTe possui uma solicitação de adiantamento vinculada.
                    </AlertDescription>
                  </Flex>
                </Flex>
                <Button
                  colorScheme="orange"
                  ml="4"
                  size="sm"
                  minW={150}
                  onClick={() => {
                    history.push(`/advance-requests/show/${cteIssuanceRequestData.advance_request_id}`)
                  }}
                >
                  Visualizar solicitação de adiantamento
                </Button>
              </Alert>
            )}
            {cteIssuanceRequestData.reject_reason && (
              <Alert status="warning" variant="left-accent">
                <Box>
                  <AlertTitle mb={2}>Motivo de reprovação!</AlertTitle>
                  <AlertDescription>{cteIssuanceRequestData.reject_reason}</AlertDescription>
                </Box>
              </Alert>
            )}
            {cteIssuanceRequestData.observation && (
              <Alert status="info" variant="left-accent" mt={4}>
                <Box>
                  <AlertTitle mb={2}>Observação!</AlertTitle>
                  <AlertDescription>{cteIssuanceRequestData.observation}</AlertDescription>
                </Box>
              </Alert>
            )}
            <Box
              aria-label="short freight details"
              rounded="lg"
              bg={bg}
              borderRadius="8"
              shadow="md"
              p={3}
              mt={4}
            >
              <Heading
                size="md"
                fontWeight="normal"
                mb={4}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                Informações solicitação de emissão de CTe
                <Tooltip label="Responsável - Data de criação" hasArrow placement="top" arrowSize={10}>
                  <Badge
                    fontSize="sm"
                    px="2"
                    py="2"
                    colorScheme="gray"
                    ml={2}
                    display="flex"
                    alignItems="center"
                  >
                    {cteIssuanceRequestData.creator?.name} - {cteIssuanceRequestData.created_at}
                  </Badge>
                </Tooltip>
              </Heading>

              <SimpleGrid minChildWidth="300px" spacing="4">
                <WrapItem>
                  <Text color="gray.400">Motorista: </Text>
                  <Text fontWeight="medium" ml="1">
                    {cteIssuanceRequestData.motorist?.name}
                  </Text>
                </WrapItem>

                <WrapItem>
                  <Text color="gray.400">Documento motorista: </Text>
                  <Text fontWeight="medium" ml="1">
                    {formatCPFAndCNPJ(cteIssuanceRequestData.motorist?.cpf)}
                  </Text>
                </WrapItem>

                <WrapItem>
                  <Text color="gray.400">Placa do veiculo: </Text>
                  <Text fontWeight="medium" ml="1">
                    {cteIssuanceRequestData.motorist_vehicle?.license_plate ?? 'Não informado'}
                  </Text>
                </WrapItem>

                <WrapItem>
                  <Text color="gray.400">Desconto Buonny: </Text>
                  <Text fontWeight="medium" ml="1">
                    {cteIssuanceRequestData.discount_buonny_formatted}
                  </Text>
                </WrapItem>

                <WrapItem>
                  <Text color="gray.400">Valor do frete:</Text>
                  <Text fontWeight="medium" ml="1">
                    {cteIssuanceRequestData.formatted_service_price}
                  </Text>
                </WrapItem>

                <WrapItem>
                  <Text color="gray.400">Valor motorista:</Text>
                  <Text fontWeight="medium" ml="1">
                    {cteIssuanceRequestData.formatted_agreed_price}
                  </Text>
                </WrapItem>
              </SimpleGrid>
            </Box>
            <Box
              aria-label="short freight details"
              rounded="lg"
              bg={bg}
              borderRadius="8"
              shadow="md"
              p={3}
              mt={4}
            >
              <Heading
                size="md"
                fontWeight="normal"
                mb={4}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                Informações do pedágio
              </Heading>

              <SimpleGrid minChildWidth="300px" spacing="4">
                <WrapItem>
                  <Text color="gray.400">Possue pedágio:</Text>
                  <Text fontWeight="medium" ml="1">
                    {cteIssuanceRequestData.has_toll ? 'Sim' : 'Não'}
                  </Text>
                </WrapItem>

                {cteIssuanceRequestData.has_toll && (
                  <WrapItem>
                    <Text color="gray.400">Descontar valor do motorista:</Text>
                    <Tooltip
                      label="Se marcado como 'Sim' indica que o valor do pedágio deve ser descontado do valor do motorista"
                      hasArrow
                      placement="top"
                      arrowSize={10}
                    >
                      <Text fontWeight="medium" ml="1">
                        {cteIssuanceRequestData.deduct_motorist_total ? 'Sim' : 'Não'}
                      </Text>
                    </Tooltip>
                  </WrapItem>
                )}

                {cteIssuanceRequestData.has_toll && (
                  <WrapItem>
                    <Text color="gray.400">Valor pedágio:</Text>
                    <Text fontWeight="medium" ml="1">
                      {cteIssuanceRequestData.formatted_toll_value}
                    </Text>
                  </WrapItem>
                )}

                <WrapItem>
                  <Text color="gray.400">Valor final do motorista:</Text>
                  <Tooltip
                    label={
                      cteIssuanceRequestData.deduct_motorist_total
                        ? '(Valor motorista) - (Valor pedágio)'
                        : null
                    }
                    hasArrow
                    placement="top"
                    arrowSize={10}
                  >
                    <Text fontWeight="medium" ml="1">
                      {cteIssuanceRequestData.total_value}
                    </Text>
                  </Tooltip>
                </WrapItem>

                <WrapItem>
                  <Text color="gray.400">Tag do pedágio:</Text>
                  <Text fontWeight="medium" ml="1">
                    {cteIssuanceRequestData.tag_toll || 'Não informado'}
                  </Text>
                </WrapItem>
              </SimpleGrid>
            </Box>
            <Box
              aria-label="short freight details"
              rounded="lg"
              bg={bg}
              borderRadius="8"
              shadow="md"
              p={3}
              mt={4}
            >
              <Heading
                size="md"
                fontWeight="normal"
                mb={4}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                Informações gerais do frete
              </Heading>

              <SimpleGrid minChildWidth="300px" spacing="4">
                <WrapItem>
                  <Text color="gray.400">Número do frete:</Text>
                  <Tooltip label="Ir para página do frete" hasArrow>
                    <Text
                      as={Link}
                      to={`/freights/show/${cteIssuanceRequestData?.freight_id}`}
                      fontWeight="medium"
                      ml="1"
                    >
                      #{cteIssuanceRequestData?.freight?.freight_number}
                    </Text>
                  </Tooltip>
                </WrapItem>

                <WrapItem>
                  <Text color="gray.400">Status do frete:</Text>
                  <Badge px="2" py="1" colorScheme="gray" ml={2}>
                    {getStatus(cteIssuanceRequestData?.freight?.status)}
                  </Badge>
                </WrapItem>

                <WrapItem>
                  <Text color="gray.400">Tipo de veículo:</Text>
                  <Text fontWeight="medium" ml="1">
                    {cteIssuanceRequestData.freight?.vehicle_categories
                      ?.map(category => category.name)
                      .join(', ')}
                  </Text>
                </WrapItem>

                <WrapItem>
                  <Text color="gray.400">Cliente (Apelido):</Text>
                  <Text fontWeight="medium" ml="1">
                    {cteIssuanceRequestData?.freight?.client?.nickname}
                  </Text>
                </WrapItem>

                <WrapItem>
                  <Text color="gray.400">Cliente CNPJ:</Text>
                  <Text fontWeight="medium" ml="1">
                    {formatCPFAndCNPJ(cteIssuanceRequestData?.freight?.client?.cnpj)}
                  </Text>
                </WrapItem>

                <WrapItem>
                  <Text color="gray.400">Solicitante:</Text>
                  <Text fontWeight="medium" ml="1">
                    {cteIssuanceRequestData?.freight?.client_contact?.name}
                  </Text>
                </WrapItem>

                <WrapItem>
                  <Text color="gray.400">Vendedor:</Text>
                  <Text fontWeight="medium" ml="1">
                    {cteIssuanceRequestData?.freight?.seller?.name}
                  </Text>
                </WrapItem>
              </SimpleGrid>
            </Box>
            {freight && (
              <Box
                aria-label="short freight details"
                rounded="lg"
                bg={bg}
                borderRadius="8"
                shadow="md"
                mt={4}
                w={boxWidth}
              >
                {isLoadingFreight ? (
                  <Flex justifyContent="center" alignItems="center" p={3}>
                    <Spinner mr={2} />
                    <Text>Carregando documentos</Text>
                  </Flex>
                ) : (
                  <ListCte freight={freight} bg={bg} />
                )}
              </Box>
            )}
            <Box
              aria-label="short freight details"
              rounded="lg"
              bg={bg}
              borderRadius="8"
              shadow="md"
              p={3}
              mt={4}
            >
              <Heading
                size="md"
                fontWeight="normal"
                mb={2}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                Arquivos Vinculados
              </Heading>

              <Tabs colorScheme="orange" w="full">
                <TabList w="full" overflowX="auto" overflowY="hidden">
                  {tabs.map(tab => (
                    <Tab
                      fontSize="sm"
                      key={tab}
                      _active={{ outline: 'none' }}
                      _focus={{ outline: 'none' }}
                      _hover={{ outline: 'none', bg: 'none' }}
                      whiteSpace="nowrap"
                    >
                      {tab}
                    </Tab>
                  ))}
                </TabList>

                <TabPanels>
                  {tabs.map(tab => (
                    <TabPanel key={tab} p="0" mt="4">
                      {tab === 'Notas Fiscais:' && (
                        <>
                          {splitAttachments &&
                          splitAttachments.nfAttachments &&
                          splitAttachments.nfAttachments.length > 0 ? (
                            <PreviewFiles images={splitAttachments.nfAttachments} displayMode="list" />
                          ) : (
                            <Text>Nenhum arquivo encontrado</Text>
                          )}
                        </>
                      )}
                    </TabPanel>
                  ))}
                </TabPanels>
              </Tabs>
            </Box>
          </>
        ) : isError ? (
          <Text textAlign="center">Erro ao carregar a solicitação de emissão de CTe</Text>
        ) : (
          <Text textAlign="center">Nenhuma solicitação de emissão de CTe encontrada</Text>
        )
      ) : (
        <Flex justifyContent="center" alignItems="center">
          <Spinner mr={2} />
          <Text>Carregando dados</Text>
        </Flex>
      )}

      {isOpenHandleCteIssuanceRequestStatusModal && (
        <HandleCteIssuanceRequestStatusModal
          isOpen={isOpenHandleCteIssuanceRequestStatusModal}
          onClose={onCloseHandleCteIssuanceRequestStatusModal}
          analysisStatus={analysisStatus}
        />
      )}

      {isOpenUpdateCteIssuanceRequestModal && cteIssuanceRequestData && (
        <UpdateCteIssuanceRequestModal
          isOpen={isOpenUpdateCteIssuanceRequestModal}
          onClose={onCloseUpdateCteIssuanceRequestModal}
          initial_value={cteIssuanceRequestData}
        />
      )}
    </Layout>
  )
}
