import { Divider, GridItem, Heading } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { FieldValues, FormState, UseFormSetValue } from 'react-hook-form'
import { InputNumberMask } from '../../../../../components/form'
import { Autocomplete } from '../../../../../components/form/Autocomplete'
import { AutocompleteAsync } from '../../../../../components/form/AutocompleteAsync'
import { Input } from '../../../../../components/form/Input'
import { RadioGroup } from '../../../../../components/form/RadioGroup'
import { Select } from '../../../../../components/form/Select'
import { AutocompleteOption } from '../../../../../components/form/types/AutocompleteOption'
import { queryClient } from '../../../../../config/react-query'
import { searchCitiesByName } from '../../../../../services/endpoints/cities/searchCities'
import { FormattedOneFreight } from '../../../../../services/endpoints/freights/getOneFreight'
import { searchBuonnyFabricators } from '../../../../../services/endpoints/vehicles/searchBuonnyFabricators'
import { searchBuonnyModels } from '../../../../../services/endpoints/vehicles/searchBuonnyModels'
import { MotoristType, VehicleType } from '../../../../../services/types'
import { buonnyItemTypesOptions } from '../../../../../services/utils/getBuonnyItemTypes'
import { buonnyTechnologiesOptions } from '../../../../../services/utils/getBuonnyTechnologies'
import { vehicleColorsBuonny } from '../../../../../services/utils/vehicleColorsBuonny'
import { alterColorsGenderBuonny } from '../../../../../services/utils/vehicleColorsBuonny'

interface FormSendToBuonnyProps {
  freight: FormattedOneFreight
  motorist: MotoristType
  vehicle: VehicleType
  setValue: UseFormSetValue<FieldValues>
  formState: FormState<FieldValues>
  isOnlyShow?: boolean
  vehicleModels?: AutocompleteOption[]
  trailerModels?: AutocompleteOption[]
  setVehicleModels?: (data: AutocompleteOption[]) => void
  setTrailerModels?: (data: AutocompleteOption[]) => void
}

export function FormSendToBuonny({
  freight,
  motorist,
  vehicle,
  setValue,
  formState,
  isOnlyShow,
  vehicleModels,
  trailerModels,
  setVehicleModels,
  setTrailerModels,
}: FormSendToBuonnyProps): JSX.Element {
  const [stolenTimes, setStolenTimes] = useState(false)
  const [accidentTimes, setAccidentTimes] = useState(false)
  const [carryTimes, setCarryTimes] = useState(false)
  const [TrackingSystem, setTrackingSystem] = useState(false)
  const [crimeProcessNumbers, setCrimeProcessNumbers] = useState(false)
  const { errors } = formState

  useEffect(() => {
    queryClient.invalidateQueries('black-list')
    queryClient.invalidateQueries('freight')
    queryClient.invalidateQueries('motorist')
    queryClient.invalidateQueries('vehicle')
    queryClient.invalidateQueries('check-pendencies')
    queryClient.invalidateQueries('find-route-points')
  }, [])

  const validateBoolean = (value: boolean | undefined): string | undefined => {
    if (typeof value !== 'boolean') return undefined
    if (value === true) return 'true'
    if (value === false) return 'false'
  }

  return (
    <>
      <GridItem colSpan={12}>
        <Heading size="md">Informações da conta</Heading>
        <Divider mt="2" />
      </GridItem>
      <GridItem colSpan={[12, 2]}>
        <Input name="codigo" setValue={setValue} label="Código" initialValue="60752" isDisabled />
      </GridItem>
      <GridItem colSpan={[12, 3]}>
        <Input name="cnpj" setValue={setValue} label="CNPJ" initialValue="37.991.170/0001-95" isDisabled />
      </GridItem>
      <GridItem colSpan={[12, 7]}>
        <Input
          name="razao_social"
          setValue={setValue}
          label="Razão Social"
          initialValue="Spotx Logística Ltda"
          isDisabled
        />
      </GridItem>

      <GridItem colSpan={12} mt="8">
        <Heading size="md">Dados de retorno</Heading>
        <Divider mt="2" />
      </GridItem>

      <GridItem colSpan={[12, 4]}>
        <Input
          name="buonny_contact_name"
          label="Nome contato"
          setValue={setValue}
          initialValue="Fernanda Salino"
          error={errors.buonny_contact_name}
          isDisabled={isOnlyShow}
        />
      </GridItem>

      <GridItem colSpan={[12, 2]}>
        <Select
          name="buonny_contact_type"
          label="Tipo"
          setValue={setValue}
          options={[
            { label: 'E-mail', value: '2' },
            { label: 'Telefone', value: '1' },
          ]}
          error={errors.buonny_contact_type}
          initialValue={freight.buonny_contact_type || '2'}
          isDisabled={isOnlyShow}
        />
      </GridItem>

      <GridItem colSpan={[12, 6]}>
        <Input
          name="buonny_contact_description"
          label="Contato"
          setValue={setValue}
          initialValue="fernanda.salino@spotx.com.br"
          error={errors.buonny_contact_description}
          isDisabled={isOnlyShow}
        />
      </GridItem>

      <GridItem colSpan={12} mt="8">
        <Heading size="md">Categoria do motorista</Heading>
        <Divider mt="2" />
      </GridItem>

      <GridItem colSpan={12}>
        <Select
          name="category"
          label="Categoria"
          setValue={setValue}
          options={[
            { label: 'Carreteiro', value: '1' },
            { label: 'Agregado', value: '2' },
            { label: 'Funcionário / Motorista', value: '3' },
            { label: 'Proprietário', value: '4' },
            { label: 'Funcionário', value: '5' },
            { label: 'Ajudante', value: '6' },
            { label: 'Conferente', value: '7' },
            { label: 'Buonny RH', value: '8' },
            { label: 'Prestador de Serviços', value: '9' },
            { label: 'Vigilante de Escolta Armado', value: '10' },
          ]}
          error={errors.category}
          initialValue={motorist.motorist_category || '1'}
          isDisabled={isOnlyShow}
        />
      </GridItem>

      <GridItem colSpan={12} mt="8">
        <Heading size="md">Dados do veículo tração (cavalo)</Heading>
        <Divider mt="2" />
      </GridItem>

      <GridItem colSpan={[12, 4]}>
        <AutocompleteAsync
          name="vehicle_brand"
          label="Marca"
          setValue={setValue}
          loadOptions={searchBuonnyFabricators}
          error={errors.vehicle_brand}
          initialValue={
            vehicle.brand_buonny && vehicle.brand_buonny !== 'undefined'
              ? {
                  label: vehicle.brand_buonny,
                  value: vehicle.brand_buonny,
                }
              : undefined
          }
          onSelectOption={async option => {
            try {
              const options = await searchBuonnyModels(String(option?.value))
              if (setVehicleModels) setVehicleModels(options)
            } catch (error) {
              if (setVehicleModels) setVehicleModels([])
            }
          }}
          isRequired
        />
      </GridItem>

      {((vehicleModels && vehicleModels.length > 0) || vehicle.model_buonny) && (
        <GridItem colSpan={[12, 6]}>
          <Autocomplete
            name="vehicle_model"
            label="Modelo"
            setValue={setValue}
            initialValue={
              vehicle.model_buonny && vehicle.model_buonny !== 'undefined'
                ? {
                    label: vehicle.model_buonny,
                    value: vehicle.model_buonny,
                  }
                : undefined
            }
            options={vehicleModels}
            error={errors.vehicle_model}
            isRequired
          />
        </GridItem>
      )}

      <GridItem colSpan={[12, 5]}>
        <Select
          name="vehicle_color"
          label="Cor"
          setValue={setValue}
          options={vehicleColorsBuonny}
          initialValue={alterColorsGenderBuonny(vehicle.color)?.toUpperCase()}
          error={errors.vehicle_color}
          isRequired
        />
      </GridItem>

      {vehicle.trailers &&
        vehicle.trailers.length > 0 &&
        vehicle.trailers.map(trailer => (
          <>
            <GridItem colSpan={12} mt="8">
              <Heading size="md">Dados do reboque (carroceria)</Heading>
              <Divider mt="2" />
            </GridItem>

            <GridItem colSpan={[12, 4]}>
              <AutocompleteAsync
                name="trailer_brand"
                label="Marca"
                setValue={setValue}
                loadOptions={searchBuonnyFabricators}
                error={errors.trailer_brand}
                initialValue={
                  trailer.brand_buonny && trailer.brand_buonny !== 'undefined'
                    ? {
                        label: trailer.brand_buonny,
                        value: trailer.brand_buonny,
                      }
                    : undefined
                }
                onSelectOption={async option => {
                  try {
                    const options = await searchBuonnyModels(String(option?.value))

                    if (setTrailerModels) setTrailerModels(options)
                  } catch (error) {
                    if (setTrailerModels) setTrailerModels([])
                  }
                }}
                isRequired
              />
            </GridItem>

            {((trailerModels && trailerModels.length > 0) || trailer.model_buonny) && (
              <GridItem colSpan={[12, 6]}>
                <Autocomplete
                  name="trailer_model"
                  label="Modelo"
                  initialValue={
                    trailer.model_buonny && trailer.model_buonny !== 'undefined'
                      ? {
                          label: trailer.model_buonny,
                          value: trailer.model_buonny,
                        }
                      : undefined
                  }
                  setValue={setValue}
                  options={trailerModels}
                  error={errors.trailer_model}
                  isRequired
                />
              </GridItem>
            )}

            <GridItem colSpan={[12, 5]}>
              <Select
                name="trailer_color"
                label="Cor"
                setValue={setValue}
                options={vehicleColorsBuonny}
                initialValue={alterColorsGenderBuonny(trailer.color)?.toUpperCase()}
                error={errors.trailer_color}
                isRequired
              />
            </GridItem>
          </>
        ))}

      <GridItem colSpan={12}>
        <RadioGroup
          name="has_tracking_system"
          label="Possui algum sistema de rastreamento?"
          setValue={setValue}
          options={[
            { label: 'Sim', value: 'true' },
            { label: 'Não', value: 'false' },
          ]}
          initialValue={validateBoolean(vehicle.has_tracker)}
          error={errors.has_tracking_system}
          onChange={e => {
            if (e === 'true') setTrackingSystem(true)
            else setTrackingSystem(false)
          }}
          isRequired
        />

        {/* <Switch
          name="has_tracking_system"
          label="Possui algum sistema de rastreamento?"
          setValue={setValue}
          error={errors.has_tracking_system}
          initialValue={vehicle.has_tracker}
          onChange={e => setTrackingSystem(e.target.checked)}

        /> */}
      </GridItem>

      {(TrackingSystem || vehicle.has_tracker) && (
        <>
          <GridItem colSpan={[12, 6]}>
            <Autocomplete
              name="vehicle_technology"
              label="Tecnologia Tração"
              setValue={setValue}
              options={buonnyTechnologiesOptions}
              error={errors.vehicle_technology}
              initialValue={
                vehicle.technology && vehicle.technology !== 'undefined'
                  ? {
                      label: vehicle.technology,
                      value: vehicle.technology,
                    }
                  : undefined
              }
            />
          </GridItem>

          {vehicle.trailers &&
            vehicle.trailers.length > 0 &&
            vehicle.trailers.map(trailer => (
              <GridItem colSpan={[12, 6]}>
                <Autocomplete
                  name="trailer_technology"
                  label="Tecnologia Reboque"
                  setValue={setValue}
                  options={buonnyTechnologiesOptions}
                  error={errors.trailer_technology}
                  initialValue={
                    trailer.technology && trailer.technology !== 'undefined'
                      ? {
                          label: trailer.technology,
                          value: trailer.technology,
                        }
                      : undefined
                  }
                />
              </GridItem>
            ))}

          <GridItem colSpan={[12, 5]}>
            <Input
              name="tracking_system"
              label="Outro tipo"
              setValue={setValue}
              initialValue={vehicle.tracking_system}
              error={errors.tracking_system}
            />
          </GridItem>
        </>
      )}

      <GridItem colSpan={12} mt="8" mb="4">
        <Heading size="md">Informações adicionais</Heading>
        <Divider mt="2" />
      </GridItem>

      <GridItem colSpan={[12, 7]}>
        <RadioGroup
          name="was_stolen_in_last_24_months"
          label="Foi roubado nos últimos 24 meses?"
          setValue={setValue}
          options={[
            { label: 'Sim', value: 'true' },
            { label: 'Não', value: 'false' },
          ]}
          initialValue={validateBoolean(motorist.was_stolen_in_last_24_months)}
          error={errors.was_stolen_in_last_24_months}
          onChange={e => {
            if (e === 'true') setStolenTimes(true)
            else setStolenTimes(false)
          }}
          isRequired
        />
        {/* <Switch
          name="was_stolen_in_last_24_months"
          label="Foi roubado nos últimos 24 meses?"
          setValue={setValue}
          error={errors.was_stolen_in_last_24_months}
          initialValue={motorist.was_stolen_in_last_24_months}
          onChange={e => setStolenTimes(e.target.checked)}

        /> */}
      </GridItem>

      {(stolenTimes || motorist.was_stolen_in_last_24_months) && (
        <GridItem colSpan={[12, 2]}>
          <InputNumberMask
            isRequired
            name="stolen_times"
            label="Quantidade"
            setValue={setValue}
            error={errors.stolen_times}
            initialValue={motorist.stolen_times ? Number(motorist.stolen_times) : undefined}
          />
        </GridItem>
      )}

      <GridItem colSpan={[12, 7]}>
        <RadioGroup
          name="had_accident"
          label="Já se envolveu em acidente?"
          setValue={setValue}
          options={[
            { label: 'Sim', value: 'true' },
            { label: 'Não', value: 'false' },
          ]}
          initialValue={validateBoolean(motorist.had_accident)}
          error={errors.had_accident}
          onChange={e => {
            if (e === 'true') setAccidentTimes(true)
            else setAccidentTimes(false)
          }}
          isRequired
        />
        {/* <Switch
          name="had_accident"
          label="Já se envolveu em acidente?"
          setValue={setValue}
          error={errors.had_accident}
          initialValue={motorist.had_accident}
          onChange={e => setAccidentTimes(e.target.checked)}

        /> */}
      </GridItem>

      {(accidentTimes || motorist.had_accident) && (
        <GridItem colSpan={[12, 2]}>
          <InputNumberMask
            isRequired
            name="accident_times"
            label="Quantidade"
            setValue={setValue}
            error={errors.accident_times}
            initialValue={motorist.accident_times ? Number(motorist.accident_times) : undefined}
          />
        </GridItem>
      )}

      <GridItem colSpan={[12, 7]}>
        <RadioGroup
          name="how_long_carry_to_company"
          isRequired
          label="Quanto tempo transportou para esta empresa?"
          setValue={setValue}
          options={[
            { label: 'Anos', value: 5 },
            { label: 'Meses', value: 6 },
            { label: 'Vezes', value: 7 },
            { label: 'Nunca transportou', value: 40 },
          ]}
          initialValue={motorist.how_long_carry_to_company}
          direction="column"
          error={errors.how_long_carry_to_company}
          onChange={e => {
            if (e !== '40') setCarryTimes(true)
            else setCarryTimes(false)
          }}
        />
      </GridItem>

      {(motorist.how_long_carry_to_company !== 40 || carryTimes) && (
        <GridItem colSpan={[12, 2]}>
          <InputNumberMask
            isRequired
            name="carry_times"
            label="Quantidade"
            setValue={setValue}
            error={errors.carry_times}
            initialValue={motorist?.carry_times}
          />
        </GridItem>
      )}

      <GridItem colSpan={[12, 7]}>
        <RadioGroup
          name="respond_for_crime_in_last_10_years"
          label="Respondeu ou responde a processo (s) crime (s) nos últimos 10 anos?"
          setValue={setValue}
          options={[
            { label: 'Sim', value: 'true' },
            { label: 'Não', value: 'false' },
          ]}
          initialValue={validateBoolean(motorist.respond_for_crime_in_last_10_years)}
          error={errors.respond_for_crime_in_last_10_years}
          onChange={e => {
            if (e === 'true') setCrimeProcessNumbers(true)
            else setCrimeProcessNumbers(false)
          }}
          isRequired
        />
        {/* <Switch
          name="respond_for_crime_in_last_10_years"
          label="Respondeu ou responde a processo (s) crime (s) nos últimos 10 anos?"
          setValue={setValue}
          error={errors.respond_for_crime_in_last_10_years}
          initialValue={motorist.respond_for_crime_in_last_10_years}
          onChange={e => setCrimeProcessNumbers(e.target.checked)}

        /> */}
      </GridItem>

      {(crimeProcessNumbers || motorist.respond_for_crime_in_last_10_years) && (
        <GridItem colSpan={[12, 5]}>
          <InputNumberMask
            name="crime_process_numbers"
            label="Informar número do (s) processo (s) no CNJ (Conselho Nacional Justiça)"
            setValue={setValue}
            error={errors.crime_process_numbers}
            initialValue={motorist.crime_process_numbers ? Number(motorist.crime_process_numbers) : undefined}
          />
        </GridItem>
      )}
      <GridItem colSpan={12} mt="8">
        <Heading size="md">Informações da carga</Heading>
        <Divider mt="2" />
      </GridItem>

      <GridItem colSpan={[12, 6]}>
        <Select
          name="buonny_cargo_type"
          label="Tipo de carga"
          setValue={setValue}
          initialValue={freight.buonny_cargo_type}
          options={[{ label: '', value: '' }, ...buonnyItemTypesOptions]}
          error={errors.buonny_cargo_type}
          isRequired
        />
      </GridItem>
      <GridItem colSpan={[12, 6]}>
        <Select
          name="buonny_cargo_price"
          label="Valor da carga"
          setValue={setValue}
          options={[
            { label: '', value: '' },
            { label: 'De R$ 0,01 a R$ 100.000,00', value: '50000' },
            { label: 'De R$ 100.000,01 a R$ 200.000,00', value: '150000' },
            { label: 'De R$ 200.000,01 a R$ 300.000,00', value: '250000' },
            { label: 'De R$ 300.000,01 a R$ 400.000,00', value: '350000' },
            { label: 'De R$ 400.000,01 a R$ 500.000,00', value: '450000' },
            { label: 'De R$ 500.000,01 a R$ 800.000,00', value: '550000' },
            { label: 'De R$ 800.000,01 a R$ 1.000.000,00', value: '900000' },
            {
              label: 'De R$ 1.000.000,01 a R$ 3.000.000,00',
              value: '2000000',
            },
            {
              label: 'De R$ 3.000.000,01 a R$ 9.999.999,00',
              value: '5000000',
            },
          ]}
          initialValue={freight.buonny_cargo_price}
          error={errors.buonny_cargo_price}
          isRequired
        />
      </GridItem>
      <GridItem colSpan={[12, 6]}>
        <AutocompleteAsync
          name="cargo_origin"
          label="Origem"
          setValue={setValue}
          loadOptions={searchCitiesByName}
          isDisabled
          initialValue={{
            label: freight.origin.name,
            value: freight.origin.ibge_id,
            uf: freight.origin.state.uf,
          }}
          error={errors.cargo_origin}
        />
      </GridItem>
      <GridItem colSpan={[12, 6]}>
        <AutocompleteAsync
          name="cargo_destination"
          label="Destino"
          setValue={setValue}
          loadOptions={searchCitiesByName}
          isDisabled
          initialValue={{
            label: freight.destination.name,
            value: freight.destination.ibge_id,
            uf: freight.destination.state.uf,
          }}
          error={errors.cargo_destination}
        />
      </GridItem>
      <GridItem colSpan={[12, 6]}>
        <Select
          name="cnpj_shipper"
          label="Embarcador"
          setValue={setValue}
          options={[
            { label: '', value: '' },
            { label: 'Sem Embarcador', value: '37991170000195' },
            {
              label: '16.404.287/0001-55 - SUZANO PAPEL E CELULOSE S.A.',
              value: '16404287000155',
            },
            {
              label: '89.637.490/0001-45 - KLABIN S.A.',
              value: '89637490000145',
            },
            {
              label: '07.175.725/0001-60 - WEG EQUIPAMENTOS ELÉTRICOS S.A.',
              value: '07175725000160',
            },
          ]}
          initialValue={freight.client.cnpj_shipper}
          error={errors.cnpj_shipper}
          isRequired
        />
      </GridItem>
    </>
  )
}
